<template>
  <div>
    <el-dialog :title="this.params.data ? '编辑' : '添加'" :visible.sync="params.show"  fullscreen :before-close="close" size="60%" :with-header="false" @open="openDialog">
      <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="120px">
        <el-form-item label="语言">
          <el-switch
            v-model="isEnglish"
            active-text="英文"
            inactive-text="中文">
          </el-switch>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-select v-model="ruleForm.type" placeholder="请选择">
            <el-option
              v-for="item in typeValue"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="封面" prop="cover_path">
          <image-upload v-model="ruleForm.cover_path"></image-upload>
        </el-form-item>

        <el-form-item v-show="!isEnglish" label="标题(中文)" prop="title_cn">
          <el-input v-model="ruleForm.title_cn" placeholder="请输入标题"></el-input>
        </el-form-item>
        <el-form-item v-show="isEnglish" label="标题(英文)" prop="title_us">
          <el-input v-model="ruleForm.title_us" placeholder="请输入标题"></el-input>
        </el-form-item>
        <el-form-item v-show="!isEnglish" label="副标题(中文)" prop="subhead_cn">
          <el-input v-model="ruleForm.subhead_cn" placeholder="请输入副标题"></el-input>
        </el-form-item>
        <el-form-item v-show="isEnglish" label="副标题(英文)" prop="subhead_us">
          <el-input v-model="ruleForm.subhead_us" placeholder="请输入副标题"></el-input>
        </el-form-item>

        <el-form-item v-show="!isEnglish" label="详细内容(中文)" prop="details_cn">
          <tinymce v-model="ruleForm.details_cn"></tinymce>
        </el-form-item>
        <el-form-item v-show="isEnglish" label="详细内容(英文)" prop="details_us">
          <tinymce v-model="ruleForm.details_us"></tinymce>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submit">确定</el-button>
          <el-button @click="close">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { editJournalism, addJournalism } from '@/api/journalism/journalism'
import tinymce from '@/components/common/tinymce.vue'
import imageUpload from '@/components/imageupload/image-upload.vue'
export default {
  components: {
    tinymce,
    imageUpload
  },
  data() {
    return {
      isEnglish: false,
      typeValue: [
      {
          label: '公司新闻',
          value: 1
        },
        {
          label: '行业动态',
          value: 2
        },
      ],
      ruleForm: {
        id: null,
        type: 1,
        title_cn: '',
        title_us: '',
        subhead_cn: '',
        subhead_us: '',
        cover_path: '',
        details_cn: '',
        details_us: '',
      },
      rules: {}
    }
  },
  props: {
    params: {
      required: true,
      type: Object,
    }
  },
  methods: {
    close() {
      this.$refs.ruleFormRef.resetFields()
      this.ruleForm = {
        id: null,
        type: 1,
        title_cn: '',
        title_us: '',
        subhead_cn: '',
        subhead_us: '',
        cover_path: '',
        details_cn: '',
        details_us: '',
      }
      this.$emit('close')
    },
    openDialog () {
      this.isEnglish = false
      if (this.params.data) {
        this.ruleForm = this.params.data
        this.rules = {}
      } else {
        this.rules = {
          type: [
            { required: true, message: '请输选择类型', trigger: 'blur' },
          ],
          title_cn: [
            { required: true, message: '请输入标题(中文)', trigger: 'blur' },
            { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' },
          ],
          title_us: [
            { required: true, message: '请输入标题(英文)', trigger: 'blur' },
            { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' },
          ],
          cover_path: [
            { required: true, message: '请上传封面', trigger: 'blur' },
          ],
          subhead_cn: [
            { max: 150, message: '长度在 1 到 50 个字符', trigger: 'blur' },
          ],
          subhead_us: [
            { max: 150, message: '长度在 1 到 50 个字符', trigger: 'blur' },
          ],
          details_cn: [
            { required: true, message: '请输入详细内容(中文)', trigger: 'blur' },
          ],
          details_us: [
            { required: true, message: '请输入详细内容(英文)', trigger: 'blur' },
          ],
        }
      }
    },
    async submit() {
      this.$refs.ruleFormRef.validate(async (v) => {
        if (!v) {
          this.$message.error('请检查是否完成所有必填项！')
          return
        }
        const result = this.params.data ? await editJournalism(this.ruleForm) : await addJournalism(this.ruleForm)
        if (result.success) {
          this.$message.success('成功')
          this.close()
        }
      })
    },
  },
  computed: {
    
  },
}
</script>
<style scoped lang="less">

</style>

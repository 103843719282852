<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>新闻动态管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row>
      <el-col :span="24">
        <el-select @change="selectChange" v-model="queryInfo.type" placeholder="请选择">
          <el-option
            v-for="item in typeValue"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-button type="primary" @click="addClick">添加</el-button>
      </el-col>
    </el-row>

    <table-ui
      :propList="propList"
      :operationProps="operationProps"
      :listData="journalismList"
      v-bind="otherProps"
      :listCount="journalismTotal"
      v-model="queryInfo"
      @page="pageChange"
    >
      <template #typeSlot="scope">{{ scope.row.type | journalismTypeFormat }}</template>
      <template #coverSlot="scope">
        <el-image slot="error" :src="scope.row.cover_path" >
          <i class="el-icon-picture-outline"></i>
        </el-image>
      </template>
      <template #operation="scope">
        <span @click="editClick(scope.row)" style="margin-right: 10px;cursor: pointer;">编辑</span>
        <el-popconfirm title="确定删除？" @confirm="deleteClick(scope.row.id)">
          <span slot="reference" style="color: red;cursor: pointer;">删除</span>
        </el-popconfirm>
      </template>
    </table-ui>
    <add-edit-journalism-dialog :params="addEditInfo" @close="addEditDialogClose"></add-edit-journalism-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import TableUi from '@/components/table-ui/table-ui.vue'
import { propList, operationProps, otherProps } from './config/journalismConfig.js'
import { deleteJournalism } from '@/api/journalism/journalism'
import addEditJournalismDialog from './cnps/add-edit-journalism-dialog.vue'
export default {
  data() {
    return {
      propList,
      operationProps,
      otherProps,
      typeValue: [
        {
          label: '全部',
          value: 0
        },
        {
          label: '公司新闻',
          value: 1
        },
        {
          label: '行业动态',
          value: 2
        },
      ],
      queryInfo: {
        cur: 1,
        size: 20,
        type: 0,
      },
      addEditInfo: {
        show: false,
        data: null,
      }
    }
  },
  components: {
    TableUi,
    addEditJournalismDialog,
  },
  mounted() {
    this.getDataList()
  },
  computed: {
    ...mapState('journalism', ['journalismList', 'journalismTotal'])
  },
  methods: {
    ...mapActions('journalism', ['getJournalismList']),
    getDataList () {
      this.getJournalismList(this.queryInfo)
    },
    pageChange(ev) {
      this.queryInfo.cur = ev.cur
      this.queryInfo.size = ev.size
      this.getDataList()
    },
    selectChange() {
      this.queryInfo.cur = 1
      this.getDataList()
    },
    // 增加
    addClick() {
      this.addEditInfo.show = true
    },
    // 编辑
    async editClick(row) {
      this.addEditInfo.data = {
        id: row.id,
        type: row.type,
        title_cn: row.title_cn,
        title_us: row.title_us,
        subhead_cn: row.subhead_cn || '',
        subhead_us: row.subhead_us || '',
        cover_path: row.cover_path,
        details_cn: row.details_cn || '',
        details_us: row.details_us || '',
      }
      this.addEditInfo.show = true
    },
    // 关闭窗口
    addEditDialogClose () {
      this.addEditInfo.show = false;
      this.addEditInfo.data = null;
      this.getDataList()
    },
    // 删除
    async deleteClick(id) {
      const result = await deleteJournalism(id)
      if (result.success) {
        this.$message.success('删除成功')
        this.getDataList()
      }
    }
  },
  
}
</script>

<style lang="less" scoped>
  .el-button {
    margin-left: 10px;
  }
</style>